import { mergeDeepLeft } from 'ramda';

import { FontSize as FontSizes, FontFamilies, ComponentGroups } from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG20: '15px',
  REG30: '18px',
  REG40: '20px',
  REG50: '22px',
  MED10: '24px',
  MED20: '26px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '50px',
    h2: '50px',
    h3: '30px',
    h4: '30px',
    h5: '30px',
    h6: '30px',
    'content-large-breakpoint': '20px',
    content: '18px',
    button: '18px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "Hackney"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "Hackney"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "Greycliff CF DemiBold"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "Greycliff CF Regular"',
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
